import React, {useRef, useState, useEffect} from 'react';
import {useQueryParam} from 'gatsby-query-params';
import axios from 'axios';
import {FormattedMessage} from "react-intl";
import Wrapper from "../SignUpSuccess/styles";
import Container from "../../../common/Container";

function SignupVerify(lang) {
    const uid = useQueryParam('uid', 'None'); // key, defaultValue
    const [verifyCloud, setVerifyCloud] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
    // Does not run on mount
    const didMountRef = useRef(false);
    useEffect(() => {
        const fetchData = async () => {
            if (didMountRef.current) {
                setIsLoading(true);
                try {
                    const result = await axios.post(
                        `${process.env.BACKEND_API_URL}/@verify-trial-cloud`,
                        {
                            uid,
                            lang,
                        },
                        {
                            headers: {
                                accept: 'application/json',
                            },
                        },
                    );
                    setVerifyCloud(true);
                } catch (error) {
                    setVerifyCloud(false);
                }
                setIsLoading(false);
            } else {
                didMountRef.current = true;
            }
        };
        fetchData();
    }, [lang, uid]);
    return (
        <>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <Wrapper as={Container}>
                    {verifyCloud ? (
                        <>
                            <h1>
                                <FormattedMessage id="signupverifysuccess_title"/>
                            </h1>
                            <p>
                                <FormattedMessage id="signupverifysuccess_text"/>
                            </p>
                        </>
                    ) : (
                        <>
                            <h1>
                                <FormattedMessage id="signupverifyfailed_title"/>
                            </h1>
                            <p>
                                <FormattedMessage id="signupverifyfailed_text"/>
                            </p>
                        </>
                    )}
                </Wrapper>
            )
            }
        </>)
}

export default SignupVerify;
