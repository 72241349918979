import React from 'react';
import Layout from '../../components/common/Layout';
import SignUpVerify from '../../components/from/SignUp/SignUpVerify';
import SEO from '../../components/SEO';

const SignUpVerifyPage = () => (
  <Layout lang="en">
    <SEO
      title="Registration successful - Nextcloud Hosting"
      description="We create your cloud now"
    />
    <SignUpVerify lang="en" />
  </Layout>
);

export default SignUpVerifyPage;
